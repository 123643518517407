import Image from 'next/image';

import { ButtonLink } from '@/components/common/ButtonLink';

const RegisterBlock = () => (
  <div className="py-4 w-full md:pt-8 md:mx-auto lg:w-11/12">
    <div className="flex flex-col justify-evenly items-center md:flex-row lg:px-4 lg:h-full lg:rounded-2xl bg-emint-black">
      <div className="flex justify-evenly w-full md:justify-start md:w-1/2">
        <span className="inline-block overflow-hidden relative w-[300px] h-[210px] md:w-[600px] md:h-[420px]">
          <Image
            src="/project-circles.png"
            alt="Your Project Realized"
            layout="fill"
            className="object-cover"
          />
        </span>
      </div>

      <div className="py-8 px-4 w-full md:w-1/2 lg:p-8">
        <div className="mb-8 space-y-2 text-sm text-white md:pl-4 md:mb-0 lg:pl-0 lg:mb-8">
          <h2 className="text-4xl font-extrabold md:text-5xl">
            <span className="block uppercase text-emint-purple">
              Your project
            </span>
            <span className="block text-white uppercase">Realized.</span>
          </h2>
          <p className="md:pr-28 md:pb-5 lg:p-0">
            Web3 is the next frontier for streaming. Be on the front lines of
            community building and project support, in the company of the
            industry’s best. Reach new audiences and create world building
            opportunities for fans. Join the next wave of professional creators!
          </p>
        </div>
        <div className="pb-4 md:pl-4 lg:p-0">
          <ButtonLink
            text="Register Your Project"
            href="/creator/signup"
            color="bg-emint-purple"
            textColor="text-white"
          />
        </div>
      </div>
    </div>
  </div>
);

const ProjectsBlock = () => (
  <div className="py-4 w-full md:pt-8 md:mx-auto lg:w-11/12">
    <div className="flex flex-col justify-evenly items-center md:flex-row lg:px-4 lg:h-full lg:rounded-2xl bg-emint-black">
      <div className="flex justify-evenly w-full md:justify-start md:w-1/2">
        <span className="inline-block overflow-hidden relative w-[300px] h-[210px] md:w-[600px] md:h-[420px]">
          <Image
            src="/access-circles.png"
            alt="Access Like Never Before"
            layout="fill"
            className="object-cover"
          />
        </span>
      </div>

      <div className="py-8 px-4 w-full md:w-1/2 lg:p-8">
        <div className="mb-8 space-y-2 text-sm text-white md:pl-4 md:mb-0 lg:pl-0 lg:mb-8">
          <h2 className="text-4xl font-extrabold md:text-5xl">
            <span className="block uppercase text-emint-purple">
              Access Like
            </span>
            <span className="block text-white uppercase">Never Before.</span>
          </h2>
          <p className="md:pr-28 md:pb-5 lg:p-0">
            Our creators don’t just want you to watch their shows. They want you
            to be IN them! Find out how to buy tickets into the making of some
            of the most exciting new content out there...and prepare to be
            amazed!
          </p>
        </div>
        <div className="pb-4 md:pl-4 lg:p-0">
          <ButtonLink
            text="Buy Tickets"
            href="/projects"
            color="bg-emint-purple"
            textColor="text-white"
          />
        </div>
      </div>
    </div>
  </div>
);

const StreamBlock = () => {
  return (
    <div className="py-4 w-full md:pt-8 md:mx-auto lg:w-11/12">
      <div className="flex flex-col justify-evenly items-center md:flex-row-reverse lg:px-4 lg:h-full lg:rounded-2xl bg-emint-black">
        <div className="flex justify-evenly w-full md:justify-start md:w-1/2">
          <span className="inline-block overflow-hidden relative w-[300px] h-[210px] md:w-[600px] md:h-[420px]">
            <Image
              src="/stream-block.png"
              alt="Start Streaming Shows"
              layout="fill"
              className="object-cover"
            />
          </span>
        </div>
        <div className="p-8 w-full md:w-1/2 md:text-right">
          <div className="mb-8 space-y-2 text-sm text-white md:mb-0 lg:pl-0 lg:mb-8">
            <h2 className="text-4xl font-extrabold md:text-5xl">
              <span className="block uppercase text-emint-white">
                Stream the
              </span>
              <span className="block uppercase text-emint-purple">
                Best + Freshest!
              </span>
            </h2>
            <p className="md:pb-5 md:pl-28 lg:p-0">
              Our talented, professional creators are producing some of the best
              content in the TV, film, and gaming industry and it’s ready for
              you to stream today! Stream and enjoy the newest content today
              with new work added weekly.
            </p>
          </div>
          <div className="pb-4 md:pl-4 lg:p-0">
            <ButtonLink
              text="Start Streaming Shows!"
              href="/stream"
              color="bg-emint-purple"
              textColor="text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const howItWorks = [
  {
    title: 'You Fund Your Faves',
    text: 'Patrons fund their favorite shows to ensure they get made and can track progress along the way!',
  },
  {
    title: 'Receive Awesome Perks',
    text: 'Limited edition merch, cast meet-n-greets, exclusive show updates, on-set tours, and more!',
  },
  {
    title: 'Attend Premieres',
    text: 'Attend the in-person or remote launch of your favorite show and celebrate the creation you helped fund!',
  },
  {
    title: 'Stream Top-Tier Content',
    text: 'Available to you anytime, a content library full of your fav films, tv shows, docs, anime and more!',
  },
  {
    title: 'Show Enters Distribution',
    text: 'We aid creators in finding distribution deals across AVOD, SVOD, and more!',
  },
];

const HowItWorksDetails = ({ title, text }) => (
  <div className="pb-5 mx-auto space-y-4 w-11/12 text-white">
    <hr />
    <h3 className="text-xl">{title}</h3>
    <p className="text-sm">{text}</p>
  </div>
);

const HowItWorksMobile = () => (
  <>
    <div className="py-10 text-center text-white">
      <h2 className="text-4xl font-extrabold uppercase">How It Works</h2>
    </div>
    {howItWorks.map(({ title, text }) => (
      <HowItWorksDetails title={title} text={text} key={title} />
    ))}
    <div className="mx-auto w-11/12 text-white">
      <hr />
    </div>
  </>
);

const HowItWorksDiagram = () => (
  <div className="lg:max-w-screen-lg h-[1100px] lg:h-[1200px] bg-[center_40%] lg:bg-[center_45%] bg-cover pt-16 mx-auto bg-[url('/how_it_works_graphic.svg')]">
    <div className="text-center text-white">
      <h2 className="font-bold uppercase md:font-extrabold text-[50px]">
        How It Works
      </h2>
    </div>
  </div>
);

const HowItWorksBlock = () => (
  <>
    <div className="md:hidden">
      <HowItWorksMobile />
    </div>
    <div className="hidden md:block">
      <HowItWorksDiagram />
    </div>
  </>
);

export const ContentBlocks = () => (
  <>
    <ProjectsBlock />
    <StreamBlock />
    <RegisterBlock />
    <HowItWorksBlock />
  </>
);
