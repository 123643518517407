import Link from 'next/link';
import { SVG, SVGType } from './SVG';

interface BtnDetailCard {
  cta: string;
  color: string;
  bgColor: string;
  url: string;
}

export interface DetailCardProps {
  title: string;
  text: string;
  icon: SVGType;
  color: string;
  btn?: BtnDetailCard;
}

export const DetailCard = ({
  title,
  text,
  icon,
  color,
  btn,
}: DetailCardProps) => (
  <div className="flex flex-col gap-2 justify-center py-4 px-2 rounded-lg md:w-1/3 bg-emint-gray-500">
    <div className="flex justify-center items-center w-full h-[45px]">
      <SVG className={`w-[45px] ${color}`} type={icon} color={color} />
    </div>
    <div className="flex flex-col gap-3 justify-end items-center">
      <h4
        className={`text-lg lg:text-xl font-bold text-center md:leading-6 ${color}`}
      >
        {title}
      </h4>
      <p className="text-sm text-center text-white">{text}</p>
      {btn?.url && btn?.color && (
        <Link href={btn.url}>
          <a
            className={`w-fit px-6 py-2 rounded-3xl cursor-pointer ${btn.color} ${btn.bgColor} hover:bg-opacity-50`}
          >
            {btn.cta}
          </a>
        </Link>
      )}
    </div>
  </div>
);
