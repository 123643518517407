import Image from 'next/image';
import Link from 'next/link';
import { PRESS } from 'src/config/zendesk';

function MediaBlock() {
  return (
    <div className="mx-auto mb-8 w-11/12 lg:w-2/3">
      <div className="mb-4 text-center text-white">
        <h2 className="uppercase">As seen in</h2>
      </div>
      <Link href={PRESS} target="_blank" rel="noreferrer">
        <a className="flex flex-wrap gap-4 justify-center">
          {[...Array(11).keys()].map((index) => (
            <Image
              key={`media-logo-${index}`}
              src={`/media-logos/${index}.png`}
              alt="Media logos"
              width={180}
              height={72}
              objectFit="contain"
            />
          ))}
        </a>
      </Link>
    </div>
  );
}

export default MediaBlock;
