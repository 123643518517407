import type { NextPage } from 'next';
import Image from 'next/image';
import { useHasMounted } from 'src/hooks/useHasMounted';

import NavHeader from '@/components/common/NavHeader';
import { CTABlock } from '@/components/CTABlock';
import { DiscordCTABlock } from '@/components/DiscordCTABlock';
import { Footer } from '@/components/Footer';
import { ContentBlocks } from '@/components/home/ContentBlocks';
import { HeroBlock } from '@/components/home/HeroBlock';
import MediaBlock from '@/components/MediaBlock';
import PageWithHead from '@/components/PageWithHead';

const Home: NextPage = () => {
  const hasMounted = useHasMounted();

  return (
    <PageWithHead>
      {hasMounted && (
        <div className="overflow-x-hidden w-full bg-emint-gray-600">
          <div className="relative md:hidden">
            <div className="relative z-10">
              <NavHeader />
            </div>
            <div className="relative h-36 md:absolute md:top-0 md:w-full md:h-full">
              <Image
                src="/banner_image.svg"
                alt="banner"
                layout="fill"
                className="object-cover"
              />
            </div>
            <HeroBlock />
          </div>

          <div className="hidden md:block">
            <div className="mx-auto max-w-screen-lg bg-[url('/banner_image.svg')] bg-auto bg-no-repeat md:bg-[right_-300px_top_1%] lg:bg-[right_-150%_top_1%]">
              <NavHeader />
              <HeroBlock />
            </div>
          </div>

          <div className="mx-auto max-w-screen-lg">
            <ContentBlocks />
            <CTABlock />
            <DiscordCTABlock />
            <MediaBlock />
            <Footer />
          </div>
        </div>
      )}
    </PageWithHead>
  );
};
export default Home;
