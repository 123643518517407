import { DetailCard, DetailCardProps } from '@/components/common/DetailCard';
import useTranslation from 'src/i18n/useTranslation';

const HeroOverlay = () => {
  const { t } = useTranslation();
  return (
    <div className="pt-5 pb-8 w-full md:bg-transparent bg-emint-gray-500">
      <div className="mx-auto w-11/12">
        <div className="flex-row items-center md:flex md:pt-5 md:pb-16">
          <div>
            <h1 className="pb-2 text-3xl font-extrabold text-white uppercase md:text-6xl inline-block md:max-w-[65%]">
              {t('homePageTitle')}
            </h1>
            <h3 className="pb-3 text-lg leading-6 md:py-3 md:text-xl text-emint-purple">
              {t('homePageSubTitle')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeroDetails = () => {
  const { t } = useTranslation();
  const details: DetailCardProps[] = [
    {
      title: t('registerYourProject'),
      text: t('whetherYouCreatingNewOrLaunch'),
      icon: 'filmReel',
      color: 'text-emint-red',
      btn: {
        cta: t('register'),
        color: 'text-white',
        bgColor: 'bg-emint-red',
        url: '/creator/signup',
      },
    },
    {
      title: t('bePartOfShow'),
      text: t('whyWatchFavoriteShowBePartOfIt'),
      icon: 'partOfShow',
      color: 'text-emint-purple',
      btn: {
        cta: t('ourShows'),
        color: 'text-white',
        bgColor: 'bg-emint-purple',
        url: '/projects',
      },
    },
    {
      title: t('streamContentNow'),
      text: t('findSomethingForEveryoneAndStream'),
      icon: 'streamContent',
      color: 'text-emint-mint',
      btn: {
        cta: t('stream'),
        color: 'text-black',
        bgColor: 'bg-emint-mint',
        url: '/stream',
      },
    },
  ];
  return (
    <div className="mx-auto w-full md:w-11/12">
      <div className="flex flex-col gap-3 p-4 w-full rounded-2xl md:flex-row bg-emint-gray-800">
        {details.map(({ title, text, icon, color, btn }) => (
          <DetailCard
            key={title}
            title={title}
            text={text}
            icon={icon}
            color={color}
            btn={btn}
          />
        ))}
      </div>
    </div>
  );
};

export const HeroBlock = () => (
  <>
    <HeroOverlay />
    <HeroDetails />
  </>
);
